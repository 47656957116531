@import 'abstracts';

/* Base */
@import './base/reset';
@import './base/alignment';

/* Vendor */
@import './vendor/bootstrap';
@import './vendor/prime';
@import './vendor/capturum-ui';
@import './vendor/font-awesone';

/* Components */
@import './components/layouts';
@import './components/buttons';
@import './components/paginator';
@import './components/confirm-dialogs';
@import './components/dynamic-dialog';
@import './components/table';
@import './components/list-renderer';
