@import 'abstracts';
@import 'emendis.theme';
@import 'blueprint-v2.theme';
@import 'styles';

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
  background: $green;
}

/** CAP-BUTTON **/
cap-button {
  p-button {
    .cap-button.p-button {
      font-weight: normal;
      padding: 0.5rem 1rem;

      @include media-breakpoint-up(xxl) {
        font-size: 1.13rem;
      }

      .p-button-label {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}

/** CAP-INPUT **/
cap-input {
  input {
    &::placeholder {
      opacity: 1;
      color: $placeholder-color;

      @include media-breakpoint-up(lg) {
        font-size: 0.75rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 0.81rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 0.88rem;
      }
    }
  }
}
