.page-wrapper {
  @include media-breakpoint-up(lg) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .page__main {
    &.main {
      padding-left: 0;
      padding-right: 0;
    }

    .main__headline {
      .headline {
        padding-top: 1rem;
        height: auto;
        border-bottom: 0;

        .headline__title {
          font-weight: bold;
          font-size: 1rem;

          @include media-breakpoint-up(lg) {
            font-size: 1.13rem;
          }

          @include media-breakpoint-up(xl) {
            font-size: 1.25rem;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 1.38rem;
          }
        }

        .actions {
          cap-button {
            margin-right: 1rem;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
