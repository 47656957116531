cpb-list-renderer {
  .cpb-list-renderer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .cpb-header-actions {
      width: 30%;
      order: 2;

      button {
        padding-top: 0.35rem;
        background: $button-gradient;

        .p-button-label, .p-button-icon {
          color: $white;
        }
      }
    }

    .header {
      order: 1;
      width: 70%;
    }

    .content {
      order: 3;
    }

    cpb-list-renderer-paginator {
      order: 4;
      width: 100%;
    }
  }
}
